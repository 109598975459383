module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Emersoft","short_name":"Emersoft","start_url":"/","background_color":"#ffffff","theme_color":"#373a47","display":"standalone","icons":[{"src":"/favicons/emersoft-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/emersoft-fav-32x32.png","sizes":"32x32 24x24","type":"image/png"},{"src":"/favicons/emersoft-57x57.png","sizes":"57x57","type":"image/png"},{"src":"/favicons/emersoft-60x60.png","sizes":"60x60","type":"image/png"},{"src":"/favicons/emersoft-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/emersoft-76x76.png","sizes":"76x76","type":"image/png"},{"src":"/favicons/emersoft-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/emersoft-114x114.png","sizes":"114x114","type":"image/png"},{"src":"/favicons/emersoft-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/emersoft-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/favicons/emersoft-icon-180x180.jpg","sizes":"180x180","type":"image/png"},{"src":"/favicons/emersoft-icon-192x192.jpg","sizes":"192x192","type":"image/png"},{"src":"/favicons/emersoft-icon-270x270.jpg","sizes":"270x270","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M65XHFDH","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"46844019","respectDNT":true,"productionOnly":true},
    }]
